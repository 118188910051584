var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-0 mt-3 mb-4 item-card flex-grow-1",class:_vm.cardClass,attrs:{"elevation":"0"},on:{"click":function($event){return _vm.clickCard()}}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_vm._l((_vm.data),function(dataItem,index){return [(dataItem.text)?_c('v-col',{key:dataItem.id,attrs:{"cols":"4"}},[_c('div',{staticClass:"py-2 pl-3"},[_vm._v(_vm._s(dataItem.text)+"：")])]):_vm._e(),_c('v-col',{key:dataItem.id,attrs:{"cols":(index === _vm.data.length - 1
              ? 6
              : dataItem.fixCol
              ? dataItem.fixCol
              : dataItem.valueCol || 8) +
              (dataItem.text ? 0 : dataItem.fixCol ? 0 : 4)}},[(dataItem.value)?_c('div',{class:[
              dataItem.class,
              !dataItem.text ? 'py-2 pl-3' : 'py-2 pr-2' ]},[(dataItem.iconText)?_c('v-icon',{staticClass:"mr-sm-1 d-sm-inline-block d-none",class:[dataItem.iconClass]},[_vm._v(" fa "+_vm._s(dataItem.iconText)+" ")]):_vm._e(),_vm._v(" "+_vm._s(dataItem.value)+" ")],1):_vm._e()])]}),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"py-2 text-center item-card-btn pointer"},[_vm._v(" "+_vm._s(_vm.actionText)+" ")])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }